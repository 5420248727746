import { useCallback, useMemo, useEffect } from 'react';
import { useSessionStorage } from 'react-use';
import { useDisclosure } from '~/app/_hooks/use-disclosure';
import { useClientOnlyMediaQuery } from '~/common/hooks/use-client-only-media-query';
import {
  buildOpenAppClickedParams,
  sendToFirebaseAnalytics,
} from '~/common/hooks/use-firebase-analytics';
import { Media } from '~/utils/styles/enums';
import { createAppInstallUrl } from '~/utils/url';
import { isBot } from '~/utils/user-agent';

const SESSION_STORAGE_KEY = 'is-shown-app-introduction';

type Params = {
  deepLinkValue: string;
};

export const useIsShownAppIntroduction = () => {
  const [isShownAppIntroduction, setIsShownAppIntroduction] = useSessionStorage(
    SESSION_STORAGE_KEY,
    false
  );
  return {
    isShownAppIntroduction,
    setIsShownAppIntroduction,
  };
};

export const useLocalHooks = ({ deepLinkValue }: Params) => {
  const screenLarge = useClientOnlyMediaQuery(Media.LARGE);
  const isMobile =
    typeof screenLarge === 'undefined' ? undefined : !screenLarge;
  const isBotUser =
    typeof window !== 'undefined' ? isBot(window.navigator.userAgent) : true;
  const { isShownAppIntroduction, setIsShownAppIntroduction } =
    useIsShownAppIntroduction();
  const actionsheetModalDisclosure = useDisclosure();

  const appLink = useMemo(() => {
    return createAppInstallUrl({
      deepLinkValue,
    });
  }, [deepLinkValue]);

  useEffect(() => {
    if (isMobile && !isShownAppIntroduction && !isBotUser) {
      actionsheetModalDisclosure.onOpen();
      setIsShownAppIntroduction(true);
    }
  }, [
    isBotUser,
    isShownAppIntroduction,
    setIsShownAppIntroduction,
    actionsheetModalDisclosure,
    isMobile,
  ]);

  const openInAppClick = useCallback(() => {
    if (typeof window === 'undefined') return;

    const eventPage = location.pathname;
    const eventParentPage = location.pathname.replace(/\/[^/]*$/, '');

    sendToFirebaseAnalytics(
      'open_app_clicked',
      buildOpenAppClickedParams({
        page: eventPage,
        parentPage: eventParentPage,
        component: 'app-introduction-actionsheet',
      })
    );

    window.location.href = appLink;
  }, [appLink]);

  return {
    actionsheetModalDisclosure,
    openInAppClick,
  };
};
